
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575px) {
    body{
        font-size: 16px;
        line-height: 1.5;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767px) {
    body{
        font-size: 16px;
        line-height: 1.5;
    }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991px) {
    body{
        font-size: 14px;
        line-height: 1.5;
    }
    .area-pagina{
        min-height: 500px;
    }
    .thumb-home{
        min-height: 400px;
    }
    .panel-body-precos{
        min-height: 200px;
    }
    
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
    body{
        font-size: 14px;
        line-height: 1.5;
    }
    .area-pagina{
        min-height: 500px;
    }
    .thumb-home{
        min-height: 420px;
    }
    .panel-body-precos{
        min-height: 150px;
    }

}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) and (max-width: 1600px){
    body{
        font-size: 16px;
        line-height: 1.5;
    }
    .area-pagina{
        min-height: 450px;
    }
    .thumb-home{
        min-height: 450px;
    }
    .sectionTitle{
        font-size: 18px;
        font-weight: 400;
    }
    .panel-body-precos{
        min-height: 150px;
    }
    .btn {
        font-size: 18px;
    }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1601px) {
    body{
        font-size: 24px;
        line-height: 1.5;
    }
    .area-pagina{
        min-height: 700px;
    }
    .img-lateral{
        width: 70%;
    }
    .thumb-home{
        min-height: 500px;
    }
    h4{
        font-size: 28px;
    }
    .sectionTitle{
        font-size: 28px;
        font-weight: 500;
    }
    .btn {
        font-size: 24px;
    }
}

@media print{
    .hide-impresso {
        display: none;
    }
    .show-impresso {
        display: inline;
    }
}

body {
    background-color:#fff;
    font-family:'Open Sans',Arial,Helvetica,sans-serif;
    -webkit-font-smoothing: antialiased;

    /*font-size:14px; line-height:1.5;*/
    color: #444;

    margin:0;
    padding-top: 0;
}

.area-pagina{
    margin-top: 50px;
}

img {
    display: block;
    margin: auto;
}

.img-lateral{
    margin-top: 20px;
}

h1.dark, h2.dark, h3.dark, h4.dark, h5.dark{
    color:#6C6C6C;
    font-weight:500;
}

a:link, a:hover {
    text-decoration:none;
}

a:hover {
    color: blue;
    cursor: pointer;
}

/* Início de Custom Navbar */

#custom.navbar-default .navbar-brand {
    padding: 0px;
}
#custom.navbar-default .navbar-brand>img {
    height: 100%;
    padding: 10px;
    width: auto;
}
#custom.navbar-default {
    font-size: 16px;
    background-color: rgba(250, 250, 250, 1);
    border-bottom-width: 0px;
}
#custom.navbar-default .navbar-nav>li>a {
    color: rgba(119, 119, 119, 1);
    background-color: rgba(250, 250, 250, 1);
}
#custom.navbar-default .navbar-nav>li>a:hover,
#custom.navbar-default .navbar-nav>li>a:focus {
    color: rgba(51, 51, 51, 1);
    background-color: rgba(30, 36, 230, 0);
}
#custom.navbar-default .navbar-nav>.active>a,
#custom.navbar-default .navbar-nav>.active>a:hover,
#custom.navbar-default .navbar-nav>.active>a:focus {
    color: rgba(85, 85, 85, 1);
    background-color: rgba(133, 181, 214, 1);
}
#custom.navbar-default .navbar-toggle {
    border-color: #85b5d6;
}
#custom.navbar-default .navbar-toggle:hover,
#custom.navbar-default .navbar-toggle:focus {
    background-color: #85b5d6;
}
#custom.navbar-default .navbar-toggle .icon-bar {
    background-color: #85b5d6;
}
#custom.navbar-default .navbar-toggle:hover .icon-bar,
#custom.navbar-default .navbar-toggle:focus .icon-bar {
    background-color: #ffffff;
}
/* Fim de Custom Navbar */

.anchor{
    display: block;
    height: 50px; /*same height as header*/
    margin-top: -50px; /*same height as header*/
    visibility: hidden;
}

.top-banner-home {
    margin-top: 50px;
    padding: 0;
    height: 700px;
    background-color: #404040;
    background-image: linear-gradient(rgba(0,0,20,0.0), rgba(0,0,20,0.0)), url(../imagens/img_topo_home.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position:center;
}

.lead-top-banner-home {
    top: 150px;
    position: relative;
    color:#ffffff;
    /*text-shadow: 2px 2px #000000;*/
}

.button-banner {
    /*text-shadow: none;*/
}

.footer {
    position: absolute;
    width: 100%;
    height:auto;
	padding-top: 20px;
    padding-bottom: 10px;
    background: #333;
	color: #aaa;
}

.footer-menu{
    line-height: 1.9;
    margin-bottom: 10px;
}

.footer-contatos{
    margin-bottom: 10px;
}

.footer-logo{
    margin-bottom: 10px;
}
.footer h4 {
	color: white;
	margin-bottom: 10px;
}

.footer a {
	color: #aaa;
}

.footer a:hover {
	color: #fff;
	text-decoration: none;
}

.sectionTitle{
    border-bottom:1px solid #E4E4E4;
    padding-bottom:5px;
    margin-top:20px;
    margin-bottom:10px;
    font-family:'Raleway',Arial,Helvetica,sans-serif;
    color: #1c91ec;
}

.section-gray {
    background-color: #F3F3F3;
    padding-top: 20px;
    padding-bottom: 20px;
}

.section-white {
    background-color: #FFFFFF;
    padding-top: 20px;
    padding-bottom: 20px;
}

.form-control {
    padding: 5px;
}

.uppercase {
    text-transform: uppercase;
}

.lowercase {
    text-transform: lowercase;
}

.ngGridStyle {
    border: 1px solid rgb(212,212,212);
    width: 100%; 
    height: 150px;
}

.special-field {
  display:none;
}
